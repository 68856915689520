import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { PostProfileProps } from '@features/profile/reducers/types'

export type StateProps = {
  page: 'profile' | 'company-profile'
  profileData?: PostProfileProps
  isModalOpen: boolean
}

const initialState: StateProps = {
  page: 'profile',
  profileData: undefined,
  isModalOpen: false,
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<StateProps['page']>) {
      state.page = action.payload
    },
    saveLocalSubmitData(state, action: PayloadAction<PostProfileProps>) {
      state.profileData = action.payload
    },
    setIsModalOpen(state, action: PayloadAction<StateProps['isModalOpen']>) {
      state.isModalOpen = action.payload
    },
  },
})

export const onboardingActions = onboardingSlice.actions

export default onboardingSlice.reducer
