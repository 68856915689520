import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

export type StateProps = {
  page: number
  inviteStatus:
    | '["Invited"]'
    | '["Accepted"]'
    | '["Rejected"]'
    | '["Confirmed"]'
  jobPostId: string
  jobTitle: string
  candidateDrawer: {
    candidateId: string
    open: boolean
  }
  interviewDrawer: {
    open: boolean
  }
}

const initialState: StateProps = {
  page: 1,
  inviteStatus: '["Invited"]',
  jobPostId: '',
  jobTitle: 'Semua loker',
  candidateDrawer: {
    candidateId: '',
    open: false,
  },
  interviewDrawer: {
    open: false,
  },
}

const interviewDetailSlice = createSlice({
  name: 'create-job',
  initialState,
  reducers: {
    setPage: (state, actions: PayloadAction<StateProps['page']>) => {
      state.page = actions.payload
    },
    setInviteStatus: (
      state,
      actions: PayloadAction<StateProps['inviteStatus']>
    ) => {
      state.inviteStatus = actions.payload
    },
    setJobPostId: (state, actions: PayloadAction<StateProps['jobPostId']>) => {
      state.jobPostId = actions.payload
    },
    setInterviewDrawer(
      state,
      action: PayloadAction<StateProps['interviewDrawer']>
    ) {
      state.interviewDrawer = action.payload
    },
    setJobTitle(state, action: PayloadAction<string>) {
      state.jobTitle = action.payload
    },
    setCandidateDrawer(
      state,
      action: PayloadAction<StateProps['candidateDrawer']>
    ) {
      state.candidateDrawer = action.payload
    },
  },
})

export const interviewDetailActions = interviewDetailSlice.actions

export const useInterviewDetailActions = () => {
  const dispatch = useDispatch()

  return useMemo(
    () => bindActionCreators(interviewDetailActions, dispatch),
    [dispatch]
  )
}

export default interviewDetailSlice.reducer
