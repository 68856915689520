import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { ChannelType } from '@custom-types/channel'
import { JobListingFilter } from '@custom-types/components/job-listing-filter'

import { ChatFilterOptionsV2, FeedbackStatusType } from '../types'

export const mapApplicationStatusFilterKeys = {
  InReview: 'Melamar',
  AssessmentPassed: 'Lolos asesmen',
  Shortlisted: 'Shortlisted',
  Rejected: 'Rejected',
  InterviewScheduled: 'Interview',
} as const

export type ApplicationStatusFilterKeys =
  keyof typeof mapApplicationStatusFilterKeys

type StateProps = {
  sendbirdChannelURL: string
  sendbirdAccessToken: string
  sendbirdUserId: string
  sendbirdAppId: string
  sendbirdChatCount: number
  openEndChatModal: boolean
  openInviteInterviewModal: boolean
  filterOptions: ChatFilterOptionsV2
  applicationStatusFilter: ApplicationStatusFilterKeys
  userData?: ChannelType
  detailLoker: {
    jobPostId: string
    isModalOpen: boolean
  }
  sendEventData: {
    eventId: string
    data: {
      jobPostId?: string
      candidateId?: string
    }
  }
  sendInterviewResult: {
    eventId: string
    data: {
      employerFeedbackStatus: FeedbackStatusType | ''
      employerFeedbackRating: number | null
      employerNotes: string
    }
  }
  candidateDrawer: {
    open: boolean
  }
  jobListingFilterModal: JobListingFilter
}

const initialState: StateProps = {
  sendbirdAccessToken: '',
  sendbirdChannelURL: '',
  sendbirdUserId: '',
  sendbirdAppId: process.env.NEXT_PUBLIC_SENDBIRD_APP_ID as string,
  sendbirdChatCount: 0,
  openEndChatModal: false,
  openInviteInterviewModal: false,
  filterOptions: {
    status: ['Active'],
    initiatedBy: ['Other'],
    uniqueTimestamp: Date.now(),
  },
  applicationStatusFilter: 'InReview',
  userData: {
    id: '',
    employerId: '',
    channelId: '',
    createdBy: '',
    status: '',
    unreadMessageCount: 0,
    isFavourited: false,
    lastActivityAt: '',
    actions: {
      canInviteForEvent: true,
    },
    eventInfo: {
      id: '',
      canInvite: false,
    },
    candidate: {
      id: '',
      fullName: '',
      gender: '',
      dateOfBirth: '',
      profilePictureURL: '',
      profileVideoURL: '',
      slug: '',
      latestWorkExperience: {
        id: '',
        location: {
          address: '',
          latitude: 0,
          longitude: 0,
          placeId: '',
          locationAddress: '',
          locationTitle: '',
          mapPlaceId: '',
          shortAddress: '',
        },
        description: '',

        industryId: '',
        medias: [],
        skillTags: [],
        toolTags: [],
        employmentType: null,
        companyId: '',
        companyLink: '',
        designation: '',
        companyName: '',
        industry: {
          id: '',
          industryName: '',
          category: '',
        },
        startDate: '',
        endDate: '',
        isWorking: false,
      },
      highestEducation: {
        institute: '',
        major: '',
        score: 0,
        degree: {
          id: '',
          degreeName: '',
        },
        startDate: '',
        endDate: '',
        isStudying: false,
      },
      documents: [],
    },
    jobPost: {
      id: '',
      title: '',
      minimumSalary: 0,
      maximumSalary: 0,
      employmentType: '',
      requiredExperienceInMonths: 0,
      company: {
        id: '',
        name: '',
        logoURL: '',
      },
    },
    screeningAssessmentInfo: {
      id: '',
      canSendAssessment: false,
    },
    jobApplication: {
      expiryAt: '',
    },
    lastMessage: {
      message: '',
    },
  },
  detailLoker: {
    jobPostId: '',
    isModalOpen: false,
  },
  sendEventData: {
    eventId: '',
    data: {
      jobPostId: '',
      candidateId: '',
    },
  },
  sendInterviewResult: {
    eventId: '',
    data: {
      employerFeedbackStatus: '',
      employerFeedbackRating: null,
      employerNotes: '',
    },
  },
  candidateDrawer: {
    open: false,
  },
  jobListingFilterModal: {
    query: {
      page: 1,
    },
    tab: 'active',
    open: false,
  },
}

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setSearchJobListingModalQueryState(
      state,
      action: PayloadAction<
        Partial<StateProps['jobListingFilterModal']['query']>
      >
    ) {
      state.jobListingFilterModal.query = {
        ...state.jobListingFilterModal.query,
        ...action.payload,
      }
    },
    changeSearchJobListingModalTab(
      state,
      action: PayloadAction<StateProps['jobListingFilterModal']['tab']>
    ) {
      state.jobListingFilterModal = {
        ...state.jobListingFilterModal,
        query: {
          page: 1,
        },
        tab: action.payload,
      }
    },
    setOpenJobListingFilterModal(state, action: PayloadAction<boolean>) {
      state.jobListingFilterModal.open = action.payload
    },
    setQueryJobListingFilterModal(
      state,
      action: PayloadAction<
        Partial<StateProps['jobListingFilterModal']['query']>
      >
    ) {
      state.jobListingFilterModal.query = {
        ...state.jobListingFilterModal.query,
        ...action.payload,
      }
    },
    setTabJobListingFilterModal(
      state,
      action: PayloadAction<StateProps['jobListingFilterModal']['tab']>
    ) {
      state.jobListingFilterModal.tab = action.payload
    },
    resetFilter: (state) => {
      state.filterOptions = {
        ...initialState.filterOptions,
      }
      state.sendbirdChannelURL = ''
    },
    setSenbirdAccessToken: (
      state,
      action: PayloadAction<StateProps['sendbirdAccessToken']>
    ) => {
      state.sendbirdAccessToken = action.payload
    },
    setSendbirdUserId: (
      state,
      action: PayloadAction<StateProps['sendbirdUserId']>
    ) => {
      state.sendbirdUserId = action.payload
    },
    setSendbirdChannelUrl: (
      state,
      action: PayloadAction<StateProps['sendbirdChannelURL']>
    ) => {
      state.sendbirdChannelURL = action.payload
    },
    setSendbirdChatCount: (
      state,
      action: PayloadAction<StateProps['sendbirdChatCount']>
    ) => {
      state.sendbirdChatCount = action.payload
    },
    setOpenEndChatModal: (
      state,
      action: PayloadAction<StateProps['openEndChatModal']>
    ) => {
      state.openEndChatModal = action.payload
    },
    setOpenInviteInterviewModal: (
      state,
      action: PayloadAction<StateProps['openInviteInterviewModal']>
    ) => {
      state.openInviteInterviewModal = action.payload
    },
    setFilterOptions: (
      state,
      action: PayloadAction<Partial<StateProps['filterOptions']>>
    ) => {
      state.filterOptions = {
        ...state.filterOptions,
        ...action.payload,
        uniqueTimestamp: Date.now(),
      }
    },
    setApplicationStatusFilter(
      state,
      action: PayloadAction<StateProps['applicationStatusFilter']>
    ) {
      state.applicationStatusFilter = action.payload
    },
    setUserData(state, action: PayloadAction<ChannelType>) {
      state.userData = action.payload
    },
    setDetailLoker(
      state,
      action: PayloadAction<{ jobPostId: string; isModalOpen: boolean }>
    ) {
      state.detailLoker = action.payload
    },
    resetFilterOptions(state) {
      state.filterOptions = {
        ...initialState.filterOptions,
        uniqueTimestamp: Date.now(),
      }
    },
    setSendEventData(
      state,
      action: PayloadAction<StateProps['sendEventData']>
    ) {
      state.sendEventData = action.payload
    },
    setSendInterviewResult(
      state,
      action: PayloadAction<StateProps['sendInterviewResult']>
    ) {
      state.sendInterviewResult = action.payload
    },
    setCandidateDrawer(
      state,
      action: PayloadAction<StateProps['candidateDrawer']>
    ) {
      state.candidateDrawer = action.payload
    },
  },
})

export const chatsActions = chatsSlice.actions

export const useChatsActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(chatsActions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}

export default chatsSlice.reducer
