import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { CandidateType } from '@custom-types/candidates'
import { JobProfileType } from '@custom-types/job-profile'
import { DegreeType } from '@custom-types/minimum-education'

type StateProps = {
  jobProfileQuery: string
  selectedJobProfiles: JobProfileType[]
  filteredJobProfiles: JobProfileType[]
  selectedDegree?: DegreeType
  selectedLocation?: {
    label: string
    value: {
      latitude: number | string
      longitude: number | string
    }
  }
  selectedWorkExperience?: {
    id: string
    name: string
  }
  minWorkEx?: number
  maxWorkEx?: number
  includeMaxWorkEx?: boolean
  includeMinWorkEx?: boolean
  selectedCandidate?: CandidateType
  candidateDetailIcon: boolean[]
}

const initialState: StateProps = {
  jobProfileQuery: '',
  selectedJobProfiles: [],
  filteredJobProfiles: [],
  selectedDegree: undefined,
  selectedLocation: undefined,
  minWorkEx: undefined,
  maxWorkEx: undefined,
  includeMaxWorkEx: undefined,
  includeMinWorkEx: undefined,
  selectedWorkExperience: undefined,
  selectedCandidate: undefined,
  candidateDetailIcon: [],
}

const candidatePortalSlice = createSlice({
  name: 'candidate-portal',
  initialState,
  reducers: {
    setJobProfileQuery: (
      state,
      action: PayloadAction<StateProps['jobProfileQuery']>
    ) => {
      state.jobProfileQuery = action.payload
    },
    setSelectedJobProfiles: (
      state,
      action: PayloadAction<StateProps['selectedJobProfiles']>
    ) => {
      state.selectedJobProfiles = action.payload
    },
    setSelectedDegree: (
      state,
      action: PayloadAction<StateProps['selectedDegree']>
    ) => {
      state.selectedDegree = action.payload
    },
    setSelectedLocation: (
      state,
      action: PayloadAction<StateProps['selectedLocation']>
    ) => {
      state.selectedLocation = action.payload
    },
    setMinWorkEx: (state, action: PayloadAction<StateProps['minWorkEx']>) => {
      state.minWorkEx = action.payload
    },
    setMaxWorkEx: (state, action: PayloadAction<StateProps['maxWorkEx']>) => {
      state.maxWorkEx = action.payload
    },
    setIncludeMinWorkEx: (
      state,
      action: PayloadAction<StateProps['includeMinWorkEx']>
    ) => {
      state.includeMinWorkEx = action.payload
    },
    setIncludeMaxWorkEx: (
      state,
      action: PayloadAction<StateProps['includeMaxWorkEx']>
    ) => {
      state.includeMaxWorkEx = action.payload
    },
    setSelectedWorkExperience: (
      state,
      action: PayloadAction<StateProps['selectedWorkExperience']>
    ) => {
      state.selectedWorkExperience = action.payload
    },
    resetWorkExp: (state) => {
      state.selectedWorkExperience = undefined
      state.minWorkEx = undefined
      state.maxWorkEx = undefined
      state.includeMaxWorkEx = undefined
      state.includeMinWorkEx = undefined
    },
    setFilteredJobProfiles: (
      state,
      action: PayloadAction<StateProps['filteredJobProfiles']>
    ) => {
      state.filteredJobProfiles = action.payload
    },
    setSelectedCandidate: (
      state,
      action: PayloadAction<StateProps['selectedCandidate']>
    ) => {
      state.selectedCandidate = action.payload
    },
    resetFilter: (state) => {
      state.jobProfileQuery = ''
      state.selectedJobProfiles = []
      state.selectedDegree = undefined
      state.selectedLocation = undefined
      state.minWorkEx = undefined
      state.maxWorkEx = undefined
      state.includeMaxWorkEx = undefined
      state.includeMinWorkEx = undefined
      state.selectedWorkExperience = undefined
      state.filteredJobProfiles = []
    },
    setCandidateDetailIcon: (
      state,
      action: PayloadAction<{ index: number; value: boolean }>
    ) => {
      const currArr = state.candidateDetailIcon
      currArr[action.payload.index] = action.payload.value
    },
  },
})

export const candidatePortalActions = candidatePortalSlice.actions

export default candidatePortalSlice.reducer
