import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { UpgradeJobState } from './types'

const initialState: UpgradeJobState = {
  origin: null,
}

const upgradeJobSlice = createSlice({
  initialState,
  name: 'upgradeJob',
  reducers: {
    setUpgradeJobOrigin(
      state,
      action: PayloadAction<UpgradeJobState['origin']>
    ) {
      state.origin = action.payload
    },
  },
})

export const useUpgradeJobActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(upgradeJobSlice.actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}

export default upgradeJobSlice
