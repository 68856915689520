import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import appReducer from '@features/app/reducers'
import { applicationsSlice } from '@features/applications/reducers'
import authReducer from '@features/auth/reducers'
import candidatePortalReducer from '@features/candidates-portal/reducers'
import chatsReducer from '@features/chats/reducers'
import coachmarksReducer from '@features/coachmarks/reducers'
import filtersReducer from '@features/filters/reducers'
import interviewDetailReducer from '@features/interviews/interview-detail/reducers/InterviewDetailReducer'
import { interviewsSlice } from '@features/interviews/reducers'
import createJobReducer from '@features/jobs/create-job/reducers'
import jobReducer from '@features/jobs/job-detail/reducers'
import { kycSlice } from '@features/kyc/reducers/KycReducer'
import loginReducer from '@features/login/reducers'
import onboardingReducer from '@features/onboarding/reducers'
import profileReducer from '@features/profile/reducers'

import { boostSlice } from './boost/slice'
import { candidateSearchSlice } from './candidate/search/slice'
import { coinsSlice } from './coins/slice'
import repostJobSlice from './jobs/repost/slice'
import upgradeJobSlice from './jobs/upgrade/slice'
import { premiumServiceSlice } from './premium-service/slice'
import { talentPoolSlice } from './talent-pool/slice'

const persistConfig = {
  key: 'root',
  version: 2,
  storage,
  whitelist: [
    'authReducer',
    'profileReducer',
    'coachmarksReducer',
    'filtersReducer',
    'appReducer',
  ],
}

const rootReducers = combineReducers({
  loginReducer,
  appReducer,
  authReducer,
  onboardingReducer,
  createJobReducer,
  profileReducer,
  jobReducer,
  chatsReducer,
  candidatePortalReducer,
  coachmarksReducer,
  filtersReducer,
  [applicationsSlice.name]: applicationsSlice.reducer,
  [kycSlice.name]: kycSlice.reducer,
  interviewDetailReducer,
  [interviewsSlice.name]: interviewsSlice.reducer,
  [candidateSearchSlice.name]: candidateSearchSlice.reducer,
  [boostSlice.name]: boostSlice.reducer,
  [coinsSlice.name]: coinsSlice.reducer,
  [talentPoolSlice.name]: talentPoolSlice.reducer,
  [premiumServiceSlice.name]: premiumServiceSlice.reducer,
  [repostJobSlice.name]: repostJobSlice.reducer,
  [upgradeJobSlice.name]: upgradeJobSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
