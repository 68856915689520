import { useRouter } from 'next/router'
import React from 'react'

import { useChatsActions } from '@features/chats/reducers/ChatsReducers'

import { PATHS } from '@constants/paths'

const ResetChatFilter = ({ children }: { children?: React.ReactNode }) => {
  const router = useRouter()
  const chatsActions = useChatsActions()

  React.useEffect(() => {
    const handleRouteChangeComplete = (nextPath: string) => {
      if (!nextPath.startsWith(PATHS.CHAT)) {
        chatsActions.resetFilterOptions()
      }
    }
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}

export default ResetChatFilter
