import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ProfileState } from './types'

const initialState: ProfileState = {
  isNewUser: undefined,
  fullName: '',
  company: undefined,
  phoneNumber: '',
  hasViewedStartModal: undefined,
  sendbirdConfiguration: undefined,
  email: '',
  contactType: '',
  profilePicture: '',
  profilePictureURL: '',
  bio: '',
  kycStatus: '',
  editProfilePage: 'about-user',
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    saveNewProfile(state, action: PayloadAction<ProfileState>) {
      const newProfile = action?.payload

      state.id = newProfile?.id
      state.fullName = newProfile?.fullName
      state.isNewUser = newProfile?.isNewUser
      state.company = newProfile?.company
      state.sendbirdConfiguration = newProfile?.sendbirdConfiguration
      state.profilePicture = newProfile?.profilePicture
      state.profilePictureURL = newProfile?.profilePictureURL
      state.kycStatus = newProfile?.kycStatus
      state.isPhantom = newProfile?.isPhantom
    },
    setCompany(state, action: PayloadAction<ProfileState['company']>) {
      const newCompany = action?.payload
      state.company = newCompany
    },
    setIsNewUser(state, action: PayloadAction<ProfileState['isNewUser']>) {
      state.isNewUser = action.payload
    },
    removeProfile(state) {
      state.isNewUser = initialState.isNewUser
      state.fullName = initialState.fullName
      state.phoneNumber = initialState.phoneNumber
      state.company = initialState.company
      state.sendbirdConfiguration = initialState.sendbirdConfiguration
      state.id = initialState.id
      state.hasViewedStartModal = initialState.hasViewedStartModal
      state.kycStatus = initialState.kycStatus
      state.isPhantom = initialState.isPhantom
    },
    setOpsStatus(state, action: PayloadAction<boolean>) {
      state.isOps = action.payload
    },
    setEditProfilePage(
      state,
      action: PayloadAction<ProfileState['editProfilePage']>
    ) {
      state.editProfilePage = action.payload
    },
  },
})

export const profileActions = profileSlice.actions

export default profileSlice.reducer
