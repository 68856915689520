import { setupInterceptor } from '@helpers/axios-instance'
import type { AppProps } from 'next/app'
import { Inter } from 'next/font/google'
import Head from 'next/head'
import React from 'react'
import 'react-day-picker/dist/style.css'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { SWRConfig } from 'swr'

import ResetChatFilter from '@components/ResetChatFilter'
import InitiateUserGlobals from '@components/initiate-user-globals'
import Appsflyer from '@components/vendors/appsflyer'
import GoogleTagManager from '@components/vendors/google-tag-manager'
import MetaPixel from '@components/vendors/meta-pixel'
import OpenGraph from '@components/vendors/opengraph'

import AuthGuard from '@guards/auth'

import fetcher from '@utils/custom-fetcher'

import '../../styles/globals.css'
import store from '../store'

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
})

const persistor = persistStore(store)
setupInterceptor(store)

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        {/* <!-- Primary Meta Tags --> */}
        <title>Atma Business | Dapatkan Karyawan Idaman Cepat dan Mudah</title>
        <OpenGraph />
      </Head>
      <GoogleTagManager />
      <Appsflyer />
      <MetaPixel />
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <AuthGuard>
            <SWRConfig
              value={{
                fetcher,
                revalidateOnFocus: false,
              }}
            >
              <InitiateUserGlobals>
                <ResetChatFilter>
                  <div className={`${inter.variable} font-sans`}>
                    <ToastContainer
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss={false}
                      draggable
                      pauseOnHover={false}
                    />
                    <Component {...pageProps} />
                  </div>
                </ResetChatFilter>
              </InitiateUserGlobals>
            </SWRConfig>
          </AuthGuard>
        </PersistGate>
      </Provider>
    </>
  )
}

export default App
