import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { RepostJobState } from './types'

const initialState: RepostJobState = {
  page: 'selecting-package',
}

const repostJobSlice = createSlice({
  initialState,
  name: 'jobRepost',
  reducers: {
    setPage: (state, actions: PayloadAction<RepostJobState['page']>) => {
      state.page = actions.payload
    },
    resetState: (state) => {
      state.page = 'selecting-package'
    },
  },
})

export const useRepostJobActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(repostJobSlice.actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}

export default repostJobSlice
