import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { authActions } from '@features/auth/reducers'

import { ATMA_EMPLOYER_ACCESS_TOKEN } from '@constants/storage-items'

import store from '../store'
import axios from './axios-instance'

export const authHeader = () => {
  const accessToken = localStorage.getItem(ATMA_EMPLOYER_ACCESS_TOKEN)

  if (accessToken) {
    return { Authorization: `Bearer ${accessToken}` }
  }

  return { Authorization: '' }
}

export const handleResponse = (response: AxiosResponse) => {
  const okResponse =
    response?.status === 200 ||
    response?.status === 201 ||
    response?.status === 204 ||
    response?.statusText === 'OK'
  if (!okResponse) {
    if ([401, 403].includes(response.status)) {
      store.dispatch(authActions.logout())
    }

    const error = response.data || response.statusText
    return Promise.reject(error)
  }

  return response
}

export const get = (url: string) => {
  const requestOptions: AxiosRequestConfig = {
    url,
    method: 'GET',
    headers: authHeader(),
  }
  return axios(requestOptions).then(handleResponse)
}

export const post = (
  url: string,
  body?: Record<PropertyKey, unknown>,
  headers?: Record<string, unknown>
) => {
  const requestOptions: AxiosRequestConfig = {
    url,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      ...headers,
    },
    data: body,
  }

  return axios(requestOptions).then(handleResponse)
}

export const put = (url: string, body?: Record<string, unknown>) => {
  const requestOptions: AxiosRequestConfig = {
    url,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    data: body,
  }
  return axios(requestOptions).then(handleResponse)
}

export const patch = (
  url: string,
  body?: Record<string, unknown>,
  headers?: Record<string, unknown>
) => {
  const requestOptions: AxiosRequestConfig = {
    url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
      ...headers,
    },
    data: body,
  }
  return axios(requestOptions).then(handleResponse)
}

// eslint-disable-next-line no-underscore-dangle
export const _delete = (url: string, body?: Record<string, unknown>) => {
  const requestOptions: AxiosRequestConfig = {
    url,
    method: 'DELETE',
    headers: authHeader(),
    data: body,
  }
  return axios(requestOptions).then(handleResponse)
}
