import {
  PayloadAction,
  bindActionCreators,
  createSlice,
} from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import {
  CandidateSortOptions,
  SortType,
} from '@constants/candidate-sort-options'

import { ApplicationsState, JobApplicantsPopupFilters } from './types'

const initialState: ApplicationsState = {
  showFeedbackModal: false,
  checkedItems: {},
  confirmShortlistModal: {
    show: false,
    type: 'add-to-shortlist',
  },
  showDetailDrawer: false,
  selectedApplicant: undefined,
  selectedChat: undefined,
  jobApplicantsFilterQueries: {
    searchText: '',
    page: 1,
    isFavourited: false,
    sortOption: CandidateSortOptions.CHANNEL_CREATED_DATE,
    sortType: SortType.ASC,
  },
  showCandidateChatDrawer: false,
  showShortlist: false,
  drawerInviteToInterview: {
    candidateId: '',
    showDrawer: false,
  },
  selectedJobId: '',
  searchJobListingModal: {
    query: {
      page: 1,
    },
    tab: 'active',
    open: false,
  },
  accessApplicantWaDocs: {
    countDocs: 0,
    countWa: 0,
    openModal: false,
  },
}

export const applicationsSlice = createSlice({
  initialState,
  name: 'applications',
  reducers: {
    incrementAccessWa(state) {
      const next = {
        ...state.accessApplicantWaDocs,
      }
      next.countWa += 1
      if (next.countWa === 10 || next.countWa === 1) {
        next.openModal = true
      }
      state.accessApplicantWaDocs = { ...next }
    },
    incrementAccessDocs(state) {
      const next = {
        ...state.accessApplicantWaDocs,
      }
      next.countDocs += 1
      if (next.countDocs === 10 || next.countDocs === 1) {
        next.openModal = true
      }
      state.accessApplicantWaDocs = { ...next }
    },
    setOpenAccessWaDocsModal(state, action: PayloadAction<boolean>) {
      state.accessApplicantWaDocs.openModal = action.payload
    },
    changeSearchJobListingModalTab(
      state,
      action: PayloadAction<ApplicationsState['searchJobListingModal']['tab']>
    ) {
      state.searchJobListingModal = {
        ...state.searchJobListingModal,
        query: {
          page: 1,
        },
        tab: action.payload,
      }
    },
    setOpenSearchJobListingModal(state, action: PayloadAction<boolean>) {
      state.searchJobListingModal.open = action.payload
    },
    setSearchJobListingModalQueryState(
      state,
      action: PayloadAction<
        Partial<ApplicationsState['searchJobListingModal']['query']>
      >
    ) {
      state.searchJobListingModal.query = {
        ...state.searchJobListingModal.query,
        ...action.payload,
      }
    },
    setSearchJobListingModalTab(
      state,
      action: PayloadAction<ApplicationsState['searchJobListingModal']['tab']>
    ) {
      state.searchJobListingModal.tab = action.payload
    },
    setSearchJobListingModalState(
      state,
      action: PayloadAction<Partial<ApplicationsState['searchJobListingModal']>>
    ) {
      state.searchJobListingModal = {
        ...state.searchJobListingModal,
        ...action.payload,
      }
    },
    setShowCandidateChatDrawer(state, action: PayloadAction<boolean>) {
      state.showCandidateChatDrawer = action.payload
    },
    setIsFavourited(
      state,
      action: PayloadAction<
        ApplicationsState['jobApplicantsFilterQueries']['isFavourited']
      >
    ) {
      state.jobApplicantsFilterQueries.isFavourited = action.payload
      state.jobApplicantsFilterQueries.page =
        initialState.jobApplicantsFilterQueries.page
      state.checkedItems = initialState.checkedItems
      state.singleCheckedCandidate = initialState.singleCheckedCandidate
    },
    setSearchDashboard(state, action: PayloadAction<string>) {
      if (action.payload === state.jobApplicantsFilterQueries.searchText) {
        // do nothing
      }
      state.jobApplicantsFilterQueries.searchText = action.payload
      state.jobApplicantsFilterQueries.page =
        initialState.jobApplicantsFilterQueries.page
      state.checkedItems = initialState.checkedItems
      state.singleCheckedCandidate = initialState.singleCheckedCandidate
    },
    setPageDashboard(state, action: PayloadAction<number>) {
      state.jobApplicantsFilterQueries.page = action.payload
      state.checkedItems = initialState.checkedItems
      state.singleCheckedCandidate = initialState.singleCheckedCandidate
    },
    toggleFeedbackModal(state, action: PayloadAction<'on' | 'off'>) {
      state.showFeedbackModal = action.payload === 'on'
      if (action.payload === 'off') {
        state.singleCheckedCandidate = undefined
      }
    },
    addCheckedItem(
      state,
      action: PayloadAction<{ id: string; item: Record<string, unknown> }>
    ) {
      state.checkedItems = {
        ...state.checkedItems,
        [action.payload.id]: { ...action.payload.item },
      }
    },
    removeCheckedItem(state, action: PayloadAction<string>) {
      const newCheckedItems = { ...state.checkedItems }
      delete newCheckedItems[action.payload]
      state.checkedItems = { ...newCheckedItems }
    },
    setCheckedItems(
      state,
      action: PayloadAction<ApplicationsState['checkedItems']>
    ) {
      state.checkedItems = { ...action.payload }
    },
    setSingleCheckedCandidate(
      state,
      action: PayloadAction<ApplicationsState['singleCheckedCandidate']>
    ) {
      state.singleCheckedCandidate = action.payload
    },
    setConfirmShortlistModalState(
      state,
      action: PayloadAction<Partial<ApplicationsState['confirmShortlistModal']>>
    ) {
      state.confirmShortlistModal = {
        ...state.confirmShortlistModal,
        ...action.payload,
      }
    },
    toggleConfirmShortlistModal(state, action: PayloadAction<'on' | 'off'>) {
      state.confirmShortlistModal.show = action.payload === 'on'
    },
    toggleDetailDrawer(state, action: PayloadAction<'on' | 'off'>) {
      state.showDetailDrawer = action.payload === 'on'
    },
    setSelectedApplicant(
      state,
      action: PayloadAction<ApplicationsState['selectedApplicant']>
    ) {
      state.selectedApplicant = action.payload
    },
    setSortType(
      state,
      action: PayloadAction<
        ApplicationsState['jobApplicantsFilterQueries']['sortType']
      >
    ) {
      state.jobApplicantsFilterQueries.sortType = action.payload
    },
    setSortOptions(
      state,
      action: PayloadAction<
        ApplicationsState['jobApplicantsFilterQueries']['sortOption']
      >
    ) {
      state.jobApplicantsFilterQueries.sortOption = action.payload
    },
    setJobApplicantsPopupFilters(
      state,
      action: PayloadAction<Partial<JobApplicantsPopupFilters>>
    ) {
      state.jobApplicantsFilterQueries = {
        ...state.jobApplicantsFilterQueries,
        ...action.payload,
        page: 1,
      }
    },
    resetJobApplicantsPopupFilters(state) {
      const { isFavourited, searchText, sortOption, sortType } =
        state.jobApplicantsFilterQueries
      state.jobApplicantsFilterQueries = {
        isFavourited,
        page: 1,
        searchText,
        sortOption,
        sortType,
      }
    },
    setSelectedChat(
      state,
      action: PayloadAction<ApplicationsState['selectedChat']>
    ) {
      state.selectedChat = action.payload
    },
    setShowShortlist(
      state,
      action: PayloadAction<ApplicationsState['showShortlist']>
    ) {
      state.showShortlist = action.payload
    },
    setDrawerInviteToInterview(
      state,
      action: PayloadAction<ApplicationsState['drawerInviteToInterview']>
    ) {
      state.drawerInviteToInterview = action.payload
    },
    setSelectJobId(
      state,
      action: PayloadAction<ApplicationsState['selectedJobId']>
    ) {
      state.selectedJobId = action.payload
    },
  },
})

export const { actions: applicationsActions, reducer: applicationsReducer } =
  applicationsSlice

export const useApplicationsActions = () => {
  const dispatch = useDispatch()
  return useMemo(
    () => bindActionCreators(applicationsActions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )
}

export default applicationsReducer
