import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import countryCode from '@constants/country-code'

export type StateProps = {
  page: 'loginOptions' | 'verifyOtp' | 'verifyEmail'
  selectedLoginIndex: number
  phoneNumber: string
  countryCode: string
  otp: string
  email: string
}

const initialState: StateProps = {
  page: 'loginOptions',
  selectedLoginIndex: 0,
  phoneNumber: '',
  countryCode: countryCode() as string,
  otp: '',
  email: '',
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<StateProps['page']>) {
      state.page = action.payload
    },
    setPhoneNumber(
      state,
      action: PayloadAction<{ phoneNumber: string; countryCode: string }>
    ) {
      state.phoneNumber = action.payload.phoneNumber
      state.countryCode = action.payload.countryCode
    },
    setEmail(state, action: PayloadAction<{ email: string }>) {
      state.email = action.payload.email
    },
    setSelectedLoginIndex(
      state,
      action: PayloadAction<StateProps['selectedLoginIndex']>
    ) {
      state.selectedLoginIndex = action.payload
    },
  },
})

export const loginActions = loginSlice.actions

export default loginSlice.reducer
